$blue: #2A83BB;
$green: #ABCE3B;


@media (max-width: 1360px) {
  .cases .circle_block {
    justify-content: center;
  }

  .main_screen .left_decoration,
  .header_section span {
    display: none;
  }
}

// Большие девайсы (большие десктопы, < 1200px)
@media (max-width: 1199.98px) {

  //HEADER

  header ul li {
    margin-right: 26px;
  }

  header .right_col {
    padding-left: 0;
  }

  .advertising_platforms .yandex_laptop_row > div:first-of-type img:first-of-type {
    position: relative;
    left: auto;
    margin-left: auto;
    margin-right: auto;
  }

  .advertising_platforms .yandex_laptop_row > div:first-of-type {
    text-align: center;
  }

  .advertising_platforms .yandex_laptop_row > div:first-of-type img:last-of-type {
    right: 122px;
  }

  .advertising_platforms .yandex_laptop_row h3 {
    text-align: center;
  }

  .advertising_platforms .yandex_laptop_row .question {
    margin-left: auto;
    margin-right: auto;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2) {
    margin-left: 28px;
    margin-right: 28px;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2):before {
    left: -45px;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2):after {
    right: -45px;
  }

  .advertising_budget .budget_wrap_bottom p {
    margin-right: 30px;
  }

  .retargeting .body_table_row .table_col {
    padding-left: 5px;
    padding-right: 5px;
  }

  .work_scheme .row_steps2,
  .work_scheme .step3:after,
  .work_scheme .row_steps1:after,
  .work_scheme .row_steps3:after,
  .work_scheme .step5:after,
  .work_scheme .step7:before,
  .work_scheme .step7:after,
  .work_scheme .row_steps3:before {
    display: none;
  }

  .work_scheme .row_steps3 {
    margin-top: -110px;
    padding-left: 315px;
  }

  .work_scheme .step:last-of-type {
    margin-top: 40px;
  }

  .work_scheme .step4:after {
    width: 124px;
  }

  .cases .case_col {
    text-align: center;
  }

  .cases h4 {
    text-align: center;
  }

  .img_bg {
    display: none;
  }

          //SHOW PROJECT PAGE

  .feature_bar .col_center {
    display: none;
  }
}

// Средние девайсы («таблетки», < 992px)
@media (max-width: 991.98px) {

  .header_section .header_line {
    display: none;
  }

  .header_section h5 {
    display: none;
  }

  //HEADER

  header {
    padding-top: 8px;
    padding-bottom: 8px;
  }

  header .right_col {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  .main_logo {
    max-width: 170px;
  }

  .hamburger {
    margin-left: 30px;
    padding: 0;
    outline: none !important;
  }

  .hamburger-box {
    width: 32px;
  }

  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 32px;
    height: 2px;
    background-color: $blue;
  }

  .hamburger--elastic .hamburger-inner::before {
    top: 8px;
  }

  .hamburger--elastic .hamburger-inner::after {
    top: 16px;
  }

  .hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -16px, 0) rotate(-270deg);
  }

  .menu_col {
    display: none;
  }

  header ul {
    padding-top: 30px;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  header ul li{
    margin-right: 0;
    margin-bottom: 15px;
  }

  //MAIN SCREEN

  .main_screen {
    padding-top: 82px;
    background: url('../img/main_screen_bg.jpg') no-repeat center top 82px;
    background-size: cover;
  }

  .main_screen > .container {
    padding-top: 10px;
  }

  .main_screen .left_decoration {
    display: none;
  }

  .main_screen .col_left {
    margin-bottom: 50px;
    padding-right: 15px;
  }

  .main_screen form {
    margin-right: auto;
  }

  .main_screen .blue_btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .service_item {
    margin-left: auto;
    margin-right: auto;
  }

  .advertising_platforms .yandex_laptop_row > div:first-of-type img:last-of-type {
    right: 10px;
  }

  .tariff_prices .body_table_row div {
    height: 90px;
  }

  .tariff_prices .body_table_row .blue_btn {
    font-size: 12px;
  }

  .tariff_prices > .container {
    padding-bottom: 100px;
  }

  .tariff_prices .tariff_question_row .question {
    margin-bottom: 26px;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2):after {
    display: none;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2):before {
    top: 130px;
    left: -107px;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2) {
    margin-left: auto;
    margin-right: 0;
  }

  .our_clients .header_section h2,
  .advantages_yandex .header_section h2,
  .advertising_platforms .header_section h2,
  .tariff_prices .header_section h2,
  .special_rate .header_section h2,
  .advertising_budget .header_section h2,
  .contacts .header_section h2 {
    display: block;
    max-width: 100%;
    width: 100%;
    text-align: center;
  }

  .advertising_budget .budget_wrap_bottom {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .advertising_budget .budget_wrap_bottom p {
    margin-right: 0;
    text-align: center;
  }

  .advertising_budget .budget_wrap_bottom .budget_bonus {
    margin-right: 0;
    margin-bottom: 30px;
  }

  .advertising_budget .budget_wrap_bottom .budget_bonus:after {
    display: none;
  }

  .initial_rate .body_table_row div {
    height: 120px;
  }

  .initial_rate .header_section a {
    top: 219px;
  }

  .retargeting > .container {
    padding-bottom: 100px;
  }

  .retargeting .header_table_row .table_col {
    line-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    height: 70px;
  }

  .yandex_auditory form {
    margin-left: auto;
    margin-right: auto;
  }

  .yandex_auditory .header_section h2 {
    max-width: 100%;
  }

  .my_advantages_row h3 {
    margin-top: 40px;
  }

  .advantage_wrap p {
    padding-left: 10px;
    padding-right: 10px;
  }

  .work_scheme .header_section h2 {
    max-width: 100%;
    width: 100%;
    text-align: center;
    display: block;
  }

  .work_scheme .row_steps1 {
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }

  .work_scheme .step1 {
    margin-right: 0;
  }

  .work_scheme .step1:after,
  .work_scheme .step2:after,
  .work_scheme .step4:after,
  .work_scheme .step6:after {
    display: none;
  }

  .work_scheme .row_steps3 {
    flex-direction: column;
    margin-top: 0;
    padding-left: 0;
    align-items: center;
  }

  .work_scheme .step:last-of-type {
    margin-top: 0;
  }

  .work_scheme .step2,
  .work_scheme .step3,
  .work_scheme .step5,
  .work_scheme .step6 {
    margin-right: 0;
  }

  .work_scheme .step .top_step {
    justify-content: center;
  }

  .work_scheme .step p {
    margin-bottom: 50px;
    text-align: center;
  }

  .work_scheme > .container {
    padding-bottom: 100px;
  }

  .header_section h2 {
    display: block;
    width: 100%;
    text-align: center;
  }

  .cases .case_col {
    margin-bottom: 70px;
  }

  .experience .experience_item {
    padding-left: 10px;
    padding-right: 10px;
  }

                      //SHOW PROJECT PAGE

  .feature_bar {
    height: 150px;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .feature_bar .col_right {
    flex-direction: column;
  }

                      //PROJECTS PAGE

  .projects {
    padding-top: 50px;
  }

  .tariff_col {
    margin-bottom: 40px;
  }
}

// Малые девайсы («ландшафтные», < 768px)
@media (max-width: 767.98px) {

  .main_logo {
    display: block;
    height: 32px;
    padding-left: 46px;
    font-size: 12px;
    line-height: 16px;
    background-size: 28px;
  }

  .header_phone_wrap p {
    margin-bottom: 0;
    font-size: 16px;
  }

  .main_screen .col_left {
    padding-top: 22px;
  }

  .main_screen h1 {
    margin-bottom: 17px;
  }

  .main_screen .photo_name span {
    color: #333;
  }

  .main_screen .photo_name {
    margin-left: auto;
    margin-right: auto;
  }

  .main_screen h1 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  .main_screen h3 {
    font-size: 16px;
    line-height: 22px;
    text-align: center;
  }

  .main_screen > .container {
    padding-bottom: 70px;
  }

  .header_section h2 {
    font-size: 28px;
    line-height: 34px;
  }

  .services > .container {
    padding-top: 33px;
    padding-bottom: 0;
  }

  .service_item {
    box-shadow: 0 15px 30px 0 rgba(0,0,0,0.1);
  }

  .services .header_section {
    margin-bottom: 35px;
  }

  .order_consultation h2 {
    margin-bottom: 15px;
    font-size: 28px;
    line-height: 34px;
  }

  .order_consultation p {
    font-size: 18px;
    line-height: 24px;
  }

  .order_consultation {
    padding-top: 43px;
    padding-bottom: 65px;
  }

  .our_clients > .container {
    padding-top: 3px;
    padding-bottom: 17px;

    .header_section {
      margin-bottom: 58px;
    }
  }

  .our_clients .client_wrap {
    margin-bottom: 48px;
  }

  .advantages_yandex > .container {
    padding-top: 15px;
    padding-bottom: 50px;
  }

  .advantages_yandex .header_section {
    margin-bottom: 69px;
  }

  .advantages_wrap {
    margin-bottom: 30px;
  }

  .advertising_platforms .tablets_row > div:nth-of-type(odd) img {
    margin-left: auto;
    margin-right: auto;
    left: 0;
  }

  .advertising_platforms .tablets_row > div:nth-of-type(even) img {
    right: 0;
  }

  .advertising_platforms > .container {
    padding-top: 15px;
  }

  .advertising_platforms .header_section {
    margin-bottom: 66px;
  }

  .advertising_platforms .tablets_row > div {
    margin-bottom: 47px;
  }

  .advertising_platforms .yandex_laptop_row > div:first-of-type img:first-of-type {
    max-width: 335px;
  }

  .advertising_platforms .yandex_laptop_row > div:first-of-type img:last-of-type {
    max-width: 206px;
    right: 68px;
  }

  .advertising_platforms .yandex_laptop_row h3 {
    display: none;
  }

  .advertising_platforms .yandex_laptop_row .question {
    font-size: 16px;
    line-height: 22px;
  }

  .advertising_platforms .yandex_laptop_row .question {
    padding-top: 28px;
    padding-bottom: 30px;
    padding-left: 30px;
    padding-right: 30px;
  }

  .advertising_platforms .yandex_laptop_row .question span {
    top: 35px;
  }

  .advertising_platforms .banners_row img {
    display: none;
  }

  .advertising_platforms .yandex_laptop_row > div:nth-of-type(2) {
    padding-top: 12px;
  }

  .advertising_platforms > .container {
    padding-bottom: 69px;
  }

  .order_consultation:nth-of-type(6) h2 {
    margin-bottom: 40px;
  }

  .desc_tariff {
    display: none;
  }

  .tariff_prices .header_section a {
    right: auto;
    top: 80px;
  }

  .tariff_prices .header_section {
    margin-bottom: 112px;
  }

  .mobile_tariff {
    display: block;

    .blue_btn {
      display: block;
      max-width: 245px;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 70px;
      margin-left: auto;
      margin-right: auto;
    }

    .row_table {

      div {
        min-height: 68px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }

      div:first-of-type {
        min-height: 60px;
        font-size: 14px;
        text-transform: uppercase;
        color: #fff;
        background-color: $green;
      }

      .bg_fd {
        background-color: #fdfdfd;
      }
    }
  }

  .tariff_prices .tariff_question_row {
    padding-top: 0;
  }


  .tariff_prices .tariff_question_row .question {
    margin-left: auto;
    margin-right: auto;
    padding-top: 24px;
    padding-bottom: 27px;
    font-size: 16px;
    line-height: 22px;
  }

  .tariff_prices .tariff_question_row ul {
    margin-left: 30px;
    padding-right: 0;
  }

  .tariff_prices .tariff_question_row .question span {
    top: 24px;
  }

  .tariff_prices > .container {
    padding-bottom: 42px;
  }

  .special_rate h3 {
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
  }

  .special_rate .rate_row {
    align-items: center;
    justify-content: center;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2) {
    margin-right: auto;
  }

  .special_rate .rate_row .rate_wrap {
    margin-bottom: 43px;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2):after {
    display: block;
    right: 104px;
    top: 178px;
  }

  .special_rate .rate_row .rate_wrap:nth-of-type(2):before {
    top: -53px;
    left: 103px;
  }

  .order_consultation:nth-of-type(8) h2 {
    margin-bottom: 30px;
  }

  .special_rate > .container {
    padding-top: 15px;
    padding-bottom: 70px;
  }

  .special_rate .header_section {
    margin-bottom: 65px;
  }

  .special_rate .sum {
    font-size: 32px;
    line-height: 28px;
  }

  .special_rate .rate_row {
    margin-bottom: 0;
  }

  .special_rate .rate_row .rate_wrap:last-of-type {
    margin-bottom: 0;
  }

  .special_rate .sum {
    margin-bottom: 35px;
  }

  .order_consultation:nth-of-type(10) h2 {
    margin-bottom: 18px;
  }

  .advertising_budget p {
    font-size: 14px;
  }

  .advertising_budget > .container {
    padding-top: 16px;
    padding-bottom: 70px;
  }

  .advertising_budget .header_section {
    margin-bottom: 110px;
  }

  .advertising_budget .budget_wrap {
    margin-bottom: 90px;
  }

  .advertising_budget .budget_wrap_bottom {
    padding-top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 50px;
  }

  .order_consultation:nth-of-type(12) h2 {
    margin-bottom: 30px;
  }

  .initial_rate,
  .retargeting,
  .order_consultation:nth-of-type(14),
  .order_consultation:nth-of-type(16) {
    display: none;
  }

  .yandex_auditory .blue_border_block {
    font-size: 16px;
    line-height: 24px;
  }

  .yandex_auditory h3 {
    font-size: 24px;
    line-height: 32px;
  }

  .yandex_auditory > .container {
    padding-top: 15px;
    padding-bottom: 70px;
  }

  .yandex_auditory .header_section {
    margin-bottom: 71px;
  }

  .yandex_auditory h3 {
    margin-top: 36px;
    margin-bottom: 20px;
  }

  .order_consultation:nth-of-type(18) h2 {
    margin-bottom: 30px;
  }

  .my_advantages_row h3 {
    font-size: 24px;
    line-height: 30px;
  }

  .guarantee_row {
    display: none;
  }

  .advantages > .container {
    padding-top: 43px;
    padding-bottom: 0;

    .header_section {
      margin-bottom: 42px;
    }
  }

  .my_advantages_row h3 {
    margin-top: 30px;
  }

  .my_advantages_row {
    margin-bottom: 0;
  }

  .work_scheme > .container {
    padding-top: 15px;
    padding-bottom: 33px;
  }

  .work_scheme .header_section {
    margin-bottom: 66px;
  }

  .work_scheme .step .top_step {
    margin-bottom: 33px;
  }

  .work_scheme .step p {
    margin-bottom: 32px;
  }

  .order_consultation:nth-of-type(22) h2 {
    margin-bottom: 29px;
  }

  .cases,
  .order_consultation:nth-of-type(24) {
    display: none;
  }

  .experience > .container {
    padding-top: 16px;
    padding-bottom: 30px;
  }

  .experience .header_section {
    margin-bottom: 70px;
  }

  .video_yandex_direct > .container {
    padding-top: 15px;
    padding-bottom: 10px;
  }

  .video_yandex_direct .header_section {
    margin-bottom: 70px;
  }

  .videos .video_desc h4 {
    font-size: 16px;
  }

  .videos .video_desc p {
    font-size: 14px;
  }

  .videos > .container {
    padding-top: 43px;
    padding-bottom: 10px;
  }

  .order_consultation:nth-of-type(29) h2 {
    margin-bottom: 28px;
  }

  .contacts > .container {
    padding-top: 15px;
    padding-bottom: 74px;
  }

  .contacts h4 {
    text-align: center;
  }

  .contacts .col_left {
    max-width: 190px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .contacts .col_center {
    max-width: 190px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    margin-bottom: 30px;
  }

  .contacts .row {
    flex-direction: column;
    align-items: center;
  }

  .contacts .col_right {
    max-width: 190px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
  }

  .contacts h4 {
    margin-bottom: 33px;
  }

  .contacts .blue_btn {
    margin-top: 50px;
  }

  footer .col_left {
    margin-bottom: 20px;
    text-align: center;
  }

  footer .col_right {
    text-align: center;
  }

  footer {
    padding-top: 35px;
  }

                  //PRYVACY PAGE

  .privacy {
    padding-top: 120px;
    padding-bottom: 50px;
  }

  .privacy .header_section {
    margin-bottom: 20px;
  }

  .privacy p {
    font-size: 14px;
    line-height: 20px;
  }

  .privacy h4 {
    margin-top: 20px;
    font-size: 20px;
  }

  .privacy ul {
    padding-left: 20px;
  }

  .privacy ul li {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 20px;
  }

  .privacy a {
    font-size: 14px;
    line-height: 20px;
  }

  //PROJECTS PAGE

  #sites_page .main_screen h1 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  #sites_page .main_screen h3 {
    font-size: 24px;
    line-height: 30px;
    text-align: center;
  }

  #sites_page .main_screen h6 {
    font-size: 18px;
    text-align: center;
  }
}

// Экстрамалые девайсы («телефоны», < 576px)
@media (max-width: 575.98px) {

  .header_phone_wrap {

  }

  .header_phone_wrap p {
    background: none;
    padding-left: 0;
    font-size: 12px;
    letter-spacing: 1px;
    color: #2A83BB;
  }

  .header_phone_wrap span {
    display: none;
  }

  .main_screen {
    background: url("../img/main_screen_bg.jpg") no-repeat center top 48px;
    background-size: cover;
  }

  .main_screen form .checkbox_desc {
    max-width: 190px;
  }

  .hamburger {
    margin-left: 10px;
    position: relative;
    top: 6px;
  }

  .tariff_prices .header_section a {
    top: 132px;
  }

  .advertising_budget .budget_wrap .budget {
    padding-top: 52px;
  }

  .advertising_budget .budget_wrap.left_budget:before,
  .advertising_budget .budget_wrap.right_budget:before {
    top: -18px;
    left: 60px;
    font-size: 40px;
  }

  .advertising_budget .budget_wrap_bottom {
    padding-top: 54px;
  }



  //THANKS PAGE

  .thanks {
    padding-top: 100px;
    padding-bottom: 80px;
  }

  .thanks h1 {
    font-size: 32px;
  }

  .thanks p {
    font-size: 18px;
    line-height: 24px;
  }
}